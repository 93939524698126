<template>
  <div ref="importTbale" class="import-tbale">
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <GlobalForm
          :init-data="initData"
          :form-item-list="formItemList"
          :inline="true"
          :labelWidth="90"
          round
          :needBtnLoading="true"
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
        >
          <el-form-item label="导入时间" slot="entryTime" style="margin-bottom: 0;">
            <el-date-picker
              v-model="entryTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="70px" label="批次号" slot="batchNo" style="margin-bottom: 0;">
            <el-input placeholder="请输入批次号" v-model="batchNo" clearable>
            </el-input>
          </el-form-item>
        </GlobalForm>
      </div>
      <div class="table-box">
        <GlobalTable
          ref="GlobalTable"
          v-loading="loading"
          :columns="tableColumns"
          :data="dataList"
          :currentPage="pages.pageNum"
          :total="pages.total"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column label="操作人" slot="optName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.optName }} - {{ row.optAccount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="导入备注" slot="aprReason" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.aprReason"
                placement="top"
              >
                <div class="nowrap-ellipsis">
                  {{ row.aprReason }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="导入订单" slot="orderNum" align="center">
            <template slot-scope="{ row }">
              <span class="btn" @click="openOrderListDia(row)"
                >{{ row.orderNum }}单</span
              >
            </template>
          </el-table-column>
          <el-table-column label="是否操作上账" slot="isRunUp" align="center">
            <template slot-scope="{ row }">
              <span :style="row.isRunUp ? 'color:#0981ff' : ''">{{
                row.isRunUp ? "是" : "否"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="上账执行状态" slot="state" align="center">
            <template slot-scope="{ row }">
              <span :style="row.state === '03' ? 'color:#FF687B' : ''">{{
                filteredState(row.state)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="上账任务情况"
            slot="runTaskInfo"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ row.runTaskInfo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            slot="operation"
            align="center"
            width="240px"
          >
            <template slot-scope="{ row }">
              <el-button
                size="small"
                type="danger"
                :plain="row.isRunUp"
                round
                @click="upAccClick(row)"
                >{{row.isRunUp?'查看上账记录':'操作上账'}}</el-button
              >
              <el-button
                size="small"
                type="success"
                round
                @click="importDataDownload(row)"
                >下载导入数据</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </GlobalChunk>
    <OrderListDia
      v-if="orderListDiaShow"
      :dialogVisible="orderListDiaShow"
      :batchNo="rowBatchNo"
      @close="orderListDiaClose"
    />
    <OperationalPostDia
      v-if="operationalPostDiaShow"
      :dialogVisible="operationalPostDiaShow"
      :batchNo="rowBatchNo"
      :rowState="rowState"
      @close="operationalPostDiaClose"
      @refresh="handleCurrentChange(1)"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import OrderListDia from "../dialog/orderListDia/index.vue";
import OperationalPostDia from "../dialog/operationalPostDia/index.vue";
import { MessageBox } from "element-ui";
import moment from "moment";
let stateOption = [
  {
    value: "00",
    label: "未执行",
  },
  {
    value: "01",
    label: "执行中",
  },
  {
    value: "02",
    label: "执行完成",
  },
  {
    value: "03",
    label: "执行中断",
  },
  {
    value: "04",
    label: "执行失败",
  },
];
export default {
  components: { OrderListDia, OperationalPostDia },
  props: {
    skipBatchNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      stateOption,
      loading: false,
      initData: null,
      entryTime: [
        moment()
          .day(moment().day() - 30)
          .format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD 23:59:59"),
      ],
      batchNo: "",
      formItemList: [
        { slotName: "entryTime" },
        { slotName: "batchNo" },
        {
          key: "orderNo",
          type: "input",
          labelName: "订单号",
          placeholder: "请输入订单号查询",
        },
        {
          key: "isRunUp",
          type: "select",
          lableWidth: "110",
          labelName: "是否操作上账",
          option: [
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "state",
          type: "select",
          lableWidth: "110",
          labelName: "上账执行状态",
          option: stateOption,
        },
      ],
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      SeachParams: {},
      dataList: [],
      tableColumns: [
        { label: "批次号", prop: "batchNo" },
        { slotName: "optName" },
        { label: "导入时间", prop: "createTime" },
        { slotName: "aprReason" },
        { slotName: "orderNum" },
        { slotName: "isRunUp" },
        { slotName: "state" },
        { slotName: "runTaskInfo" },
        { slotName: "operation" },
      ],
      rowBatchNo: "",
      rowState: "",
      orderListDiaShow: false,
      operationalPostDiaShow: false,
    };
  },
  created() {
    if(this.skipBatchNo){
      this.batchNo = this.skipBatchNo
      this.$emit('clearSkipBatchNo')
    }
    this.handleConfirm();
  },
  mounted() {},
  methods: {
    // 查询
    handleConfirm(data, cd) {
      let startTime = "";
      let endTime = "";
      if (this.entryTime === null) {
        startTime = "";
        endTime = "";
      } else {
        startTime = moment(this.entryTime[0]).format("x");
        endTime = moment(this.entryTime[1]).format("x");
      }
      this.SeachParams = {
        batchNo: this.batchNo,
        isRunUp: data && data.isRunUp,
        orderNo: data && data.orderNo ? data.orderNo : "",
        state: data && data.state ? data.state : "",
        startTime: startTime,
        endTime: endTime,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      if (val) {
        this.SeachParams.pageNum = val;
      }
      this.loading = true;
      _api
        .aprImportLogList(this.SeachParams)
        .then((res) => {
          if (res.code === 1) {
            this.dataList = res.data.records;
            this.pages.total = res.data.total;
            this.pages.pageNum = res.data.current;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding === "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 操作上账
    upAccClick(row) {
        this.rowBatchNo = row.batchNo;
        this.rowState = row.state;
        this.operationalPostDiaShow = true
    },
    // 过滤状态
    filteredState(state) {
      let label = "";
      this.stateOption.forEach((item) => {
        if (item.value === state) {
          label = item.label;
        }
      });
      return label;
    },
    orderListDiaClose() {
      this.orderListDiaShow = false;
      this.rowBatchNo = "";
    },
    openOrderListDia(row) {
      this.rowBatchNo = row.batchNo;
      this.orderListDiaShow = true;
    },
    operationalPostDiaClose() {
      this.operationalPostDiaShow = false;
      this.rowBatchNo = "";
    },
    importDataDownload(row) {
      this.loading = true
      _api
        .excelImportLogListByBatch({
          batchNo: row.batchNo,
        })
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);

          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "导入数据.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        });
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.import-tbale {
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .btn {
    color: #0981ff;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }
}
</style>
