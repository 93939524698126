<template>
  <div ref="upAccTable" class="upacc-tbale">
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <GlobalForm
          :init-data="initData"
          :form-item-list="formItemList"
          :inline="true"
          :labelWidth="90"
          round
          :needBtnLoading="true"
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
        >
          <el-form-item label="上账时间" slot="entryTime" style="margin-bottom: 0;">
            <el-date-picker
              v-model="entryTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
          label-width="70px"
            label="批次号"
            slot="batchNo"
            style="margin-bottom: 0;"
          >
            <el-input placeholder="请输入批次号" v-model="batchNo" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="上账操作人" slot="optAccount" style="margin-bottom: 0;">
            <el-select
              v-model="optAccount"
              filterable
              clearable
              placeholder="请输入或选择操作人"
            >
              <el-option
                v-for="item in staffList"
                :key="item.userAccount"
                :label="item.userName"
                :value="item.userAccount"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="回收商" slot="merchantId" style="margin-bottom: 0;">
            <el-select
              v-model="merchantId"
              placeholder="请输入选择回收商名称"
              filterable
              clearable
            >
              <el-option
                v-for="item in merchantSelectList"
                :key="item.merchantId"
                :label="
                  item.merchantEnterType == '01'
                    ? item.merchantName + '(外部)'
                    : item.merchantName
                "
                :value="item.merchantId"
              ></el-option>
            </el-select>
          </el-form-item>
        </GlobalForm>
      </div>
      <div class="table-box">
        <GlobalTable
          ref="GlobalTable"
          v-loading="loading"
          :columns="tableColumns"
          :data="dataList"
          :currentPage="pages.pageNum"
          :total="pages.total"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column label="导入批次号" slot="batchNo" align="center">
            <template slot-scope="{ row }">
              <span class="btn" @click="seeImport(row)">{{ row.batchNo }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="上账回收商"
            slot="merchantName"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.merchantName"
                placement="top"
              >
                <div class="nowrap-ellipsis">
                  {{ row.merchantName }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="上账订单" slot="orderNum" align="center">
            <template slot-scope="{ row }">
              <span class="btn" @click="openOrderListDia(row)">{{ row.orderNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="上账状态" slot="execState" align="center">
            <template slot-scope="{ row }">
              <span :style="row.execState === '02' ? 'color:#FF687B' : ''">{{
                row.execState === "00"
                  ? "未开始"
                  : row.execState === "01"
                  ? "上账成功"
                  : "上账失败"
              }}</span>
              <div class="btn" v-if="row.execState === '01'" @click="toLink(row)">查看账务记录</div>
            </template>
          </el-table-column>
          <el-table-column label="上账备注" slot="aprReason" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.aprReason"
                placement="top"
              >
                <div class="nowrap-ellipsis">
                  {{ row.aprReason }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="上账操作人" slot="optName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.optName }} - {{ row.optAccount }}</span>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </GlobalChunk>
    <OrderListDia
      v-if="orderListDiaShow"
      :dialogVisible="orderListDiaShow"
      :batchNo="rowBatchNo"
      :merchantId="rowMerchantId"
      @close="orderListDiaClose"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import OrderListDia from "../dialog/orderListDia/index.vue";
import moment from "moment";
export default {
  components: { OrderListDia },
  data() {
    return {
      loading: false,
      initData: null,
      entryTime: [
        moment()
          .day(moment().day() - 30)
          .format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD 23:59:59"),
      ],
      batchNo: "",
      formItemList: [
        { slotName: "entryTime" },
        { slotName: "batchNo" },
        {
          key: "orderNo",
          type: "input",
          labelName: "订单号",
          placeholder: "请输入订单号查询",
        },
        { slotName: "optAccount" },
        { slotName: "merchantId" },
      ],
      merchantSelectList: [],
      optAccount: "",
      merchantId: "",
      dataList: [],
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      SeachParams: {},
      tableColumns: [
        { slotName: "batchNo" },
        { slotName: "merchantName" },
        { slotName: "orderNum" },
        { label: "上账金额", prop: "upAccountAmount" },
        { slotName: "execState" },
        { slotName: "aprReason" },
        { slotName: "optName" },
        { label: "上账时间", prop: "createTime" },
      ],
      staffList: [],
      rowBatchNo: "",
      rowMerchantId: "",
      orderListDiaShow: false,
    };
  },
  created() {
    this.getStaffList();
    this.getMerchantSelect();
    this.handleConfirm()
  },
  mounted() {},
  methods: {
    // 查询
    handleConfirm(data, cd) {
      let startTime = "";
      let endTime = "";
      if (this.entryTime === null) {
        startTime = "";
        endTime = "";
      } else {
        startTime = moment(this.entryTime[0]).format("x");
        endTime = moment(this.entryTime[1]).format("x");
      }
      this.SeachParams = {
        batchNo: this.batchNo,
        orderNo: data && data.orderNo ? data.orderNo : "",
        optAccount: this.optAccount,
        merchantId: this.merchantId,
        startTime: startTime,
        endTime: endTime,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      if (val) {
        this.SeachParams.pageNum = val;
      }
      this.loading = true;
      _api
        .getImportLogUpList(this.SeachParams)
        .then((res) => {
          if (res.code === 1) {
            this.dataList = res.data.ipage.records;
            this.pages.total = res.data.ipage.total;
            this.pages.pageNum = res.data.ipage.current;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding === "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 操作上账
    upAccClick(row) {},
    // 过滤状态
    filteredState(state) {
      let label = "";
      this.stateOption.forEach((item) => {
        if (item.value === state) {
          label = item.label;
        }
      });
      return label;
    },

    getStaffList() {
      _api.StaffManageList({ pageSize: 99999 }).then((res) => {
        this.staffList = res.data.records;
      });
    },
    getMerchantSelect() {
      _api.listSimpleMerchantView({ isFilterMatrix: "02" }).then((res) => {
        if (res.code === 1) {
          this.merchantSelectList = res.data;
        }
      });
    },
    orderListDiaClose() {
      this.orderListDiaShow = false;
      this.rowBatchNo = "";
      this.rowMerchantId = "";
    },
    openOrderListDia(row) {
      this.rowBatchNo = row.batchNo;
      this.rowMerchantId = row.merchantId;
      this.orderListDiaShow = true;
    },
    seeImport(row) {
      this.$emit('seeImport',row.batchNo)
    },
    toLink(row) {
      let routeData = this.$router.resolve({
        path: "/payment/PaybAccount",
        query: { merchantId: row.merchantId },
      });
      window.open(routeData.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.upacc-tbale {
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .btn {
    color: #0981ff;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }
}
</style>
