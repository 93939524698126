<template>
  <div class="box">
    <GlobalInfoBar
      title="导入线下打款"
      content="查看导入线下打款的记录以及操作上账"
    />
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div
            :class="clickShow === '01' ? 'left' : 'right'"
            @click="tabChange('01')"
          >
          导入记录
          </div>
          <div
            :class="clickShow === '02' ? 'left2' : 'right'"
            @click="tabChange('02')"
          >
          上账记录
          </div>
        </div>
      </div>
    </div>
    <template v-if="clickShow === '01'">
        <ImportTbale :skipBatchNo="skipBatchNo" @clearSkipBatchNo="clearSkipBatchNo"/>
    </template>
    <template v-if="clickShow === '02'">
        <UpAccTable @seeImport="seeImport"/>
    </template>
  </div>
</template>
<script>
import _api from "@/utils/request";
import ImportTbale from "./importTbale/index.vue";
import UpAccTable from "./upAccTable/index.vue";

export default {
  name: "importRecords",
  components: { ImportTbale,UpAccTable },
  data() {
    return {
      clickShow: "01",
      skipBatchNo: ""
    };
  },
  created() {},
  methods: {
    tabChange(type) {
        this.clickShow = type;
    },
    seeImport(val) {
      this.skipBatchNo = val
      this.tabChange('01')
    },
    clearSkipBatchNo() {
      this.skipBatchNo = ""
    }
  },
};
</script>
<style lang="scss" scoped>
.button_top {
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faultBtn {
  width: 280px;
  // height: 42px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  .left {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 42px;
    height: 42px;
    background: #0981ff;
    border-radius: 20px 0 0 20px;
  }

  .left2 {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 42px;
    height: 42px;
    background: #0981ff;
    border-radius: 0 20px 20px 0;
  }

  .right {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 42px;
    height: 42px;
    border-radius: 20px;
  }
}
</style>
