<template>
  <div class="see-model-dia">
    <el-dialog
      :title="'操作上账—' + stateTxt"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="1000px"
      top="3%"
      @close="close"
    >
      <div class="flex" style="color: #0981ff;margin-bottom: 20px;">
        <p style="margin-right: 50px;">批次号：{{ batchNo }}</p>
        <p>导入时间：{{ createTime }}</p>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="tableColumns"
        :maxHeight="600"
        :data="dataList"
        :isPagination="false"
      >
        <el-table-column label="上账回收商" slot="merchantName" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.merchantName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.merchantName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="线下打款订单" slot="orderNum" align="center">
          <template slot-scope="{ row }">
            <span class="btn" @click="openOrderListDia(row)">{{
              row.orderNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="导入打款金额" slot="rePayAmount" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.rePayAmount }}元</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="平台补贴金额"
          slot="platformSubsidyAmount"
          align="center"
          min-width="120"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <el-input
                :disabled="state!='00' && state!='04'"
                type="number"
                size="mini"
                style="width: 80px;"
                onkeyup="value=value.replace(/^(-1)|[^\d]+/g,'')"
                oninput="if(value > 1000) value=1000"
                min="0"
                @input="platAmountChange($event, $index)"
                v-model="row.platformSubsidyAmount"
                placeholder="请输入"
              ></el-input
              >元
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          label="上账金额"
          slot="upAccountAmount"
          align="center"
        >
          <template slot-scope="{ row }">
            <span style="color:#FF687B;">{{ row.upAccountAmount }}元</span>
          </template>
        </el-table-column>
        <el-table-column
          label="上账备注"
          slot="aprReason"
          align="center"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <el-input
              :disabled="state!='00' && state!='04'"
              placeholder="请输入上账备注"
              size="mini"
              :rows="2"
              type="textarea"
              v-model="row.aprReason"
              clearable
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="上账执行状态" slot="execState" align="center">
          <template slot-scope="{ row }">
            <div :style="row.execState === '02'?'color:#FF687B;':''">{{
              row.execState === "00"
                ? "未开始"
                : row.execState === "01"
                ? "上账成功"
                : "上账失败"
            }}</div>
            <div style="color:#FF687B;" v-if="row.execState === '02'">
              {{ row.errorMsg }}
            </div>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">关闭</el-button>
        <el-button
          v-if="state === '00'"
          type="primary"
          @click.native="sureClick"
          >确定上账</el-button
        >
        <el-button
          v-if="state === '01'"
          type="primary"
          :loading="refreshBtnLoading"
          @click.native="refresh"
          >刷新</el-button
        >
        <el-button
          v-if="state === '04'"
          type="primary"
          @click.native="sureClick"
          >重新上账</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="dialogSureVisible" :close-on-click-modal="false"
      width="500px" @closed="rowId=''">
      <div style="font-size: 16px; color: #ff687b;text-align: center;">说明:上账后不可撤销，请仔细核实上账金额及商户</div>
      <div style="font-size: 18px;color: #666666;text-align: center;height: 100px;line-height: 100px;">
        是否确认操作上账？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button type="primary" :loading="sureBtnLoading" @click.native="sureHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <OrderListDia
      v-if="orderListDiaShow"
      :dialogVisible="orderListDiaShow"
      :batchNo="batchNo"
      :merchantId="merchantId"
      @close="orderListDiaClose"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import OrderListDia from "../orderListDia/index.vue";

export default {
  components: { OrderListDia },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    batchNo: {
      type: String,
      default: "",
    },
    rowState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      createTime: "",
      dataList: [],
      stateTxt: "未开始",
      state: "",
      tableColumns: [
        { slotName: "merchantName" },
        { slotName: "orderNum" },
        { slotName: "rePayAmount" },
        { slotName: "platformSubsidyAmount" },
        { slotName: "upAccountAmount" },
        { slotName: "aprReason" },
        { slotName: "execState" },
      ],
      stateOption: [
        { label:'未执行', value: '00'},
        { label:'执行中', value: '01'},
        { label:'执行完成', value: '02'},
        { label:'执行中断', value: '03'},
        { label:'执行失败', value: '04'},
      ],
      sureBtnLoading: false,
      orderListDiaShow: false,
      merchantId: "",
      dialogSureVisible: false
    };
  },

  created() {
    this.getOrderList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getOrderList() {
      let params = {
        batchNo: this.batchNo,
      };
      this.loading = true;
      _api
        .getImportLogUpInfoByBatch(params)
        .then((res) => {
          if (res.code === 1) {
            this.dataList = res.data.list;
            this.createTime = res.data.createTime;
            this.state = res.data.state
            this.stateTxt = this.stateOption.find(option => option.value === res.data.state).label;
            if(res.data.state != this.rowState){
              this.$emit('refresh')
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.refreshBtnLoading = false;
        });
    },
    sureClick() {
      this.dialogSureVisible = true
    },
    sureHandleSuccess() {
      let merchantList = [];
      this.dataList.forEach((item) => {
        merchantList.push({
          aprReason: item.aprReason,
          merchantId: item.merchantId,
          platformSubsidyAmount: item.platformSubsidyAmount,
          upAccountAmount: item.upAccountAmount,
        });
      });
      this.sureBtnLoading = true;
      let params = {
        batchNo: this.batchNo,
        merchantList,
      };
      _api
        .doImportLogUpInfoByBatch(params)
        .then((res) => {
          if (res.code === 1) {
            this.getOrderList();
          }
          this.dialogSureVisible = false
          this.sureBtnLoading = false;
        }).catch(() => {
          this.sureBtnLoading = false;
        })
    },
    platAmountChange(event, index) {
      this.dataList[index].upAccountAmount =
        Number(event) + Number(this.dataList[index].rePayAmount);
      this.$forceUpdate();
    },
    refresh() {
      this.refreshBtnLoading = true
      this.getOrderList()
    },
    orderListDiaClose() {
      this.orderListDiaShow = false;
      this.merchantId = ""
    },
    openOrderListDia(row) {
      this.rowBatchNo = row.batchNo;
      this.merchantId = row.merchantId;
      this.orderListDiaShow = true;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.flex {
  display: flex;
  align-items: center;
}
.nowrap-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.btn {
  color: #0981ff;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
