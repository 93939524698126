<template>
  <div class="see-model-dia">
    <el-dialog
      title="查看订单"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="900px"
      top="3%"
      @close="close"
    >
      <div class="flex" style="color: #0981ff;margin-bottom: 20px;">
        <p style="margin-right: 50px;">批次号：{{ batchNo }}</p>
        <p>导入时间：{{ createTime }}</p>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="tableColumns"
        :maxHeight="600"
        :data="dataList"
        :isPagination="false"
      >
        <el-table-column label="回收商" slot="merchantName" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.merchantName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.merchantName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" slot="state" align="center">
          <template slot-scope="{ row }">
            <span>{{ filteredOrderState(row.orderState) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追差状态" slot="aprState" align="center">
          <template slot-scope="{ row }">
            <span>{{ filteredAprState(row.aprState) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="打款方式" slot="moneyWay" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.moneyWay === "00" ? "线下打款" : "线上打款" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否导入上账"
          slot="isImportUpAccount"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.isImportUpAccount ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上账操作人" slot="optName" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.optName }} - {{ row.optAccount }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="close">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    batchNo: {
      type: String,
      default: "",
    },
    merchantId: [String,Number],
  },
  data() {
    return {
      loading: false,
      createTime: "",
      dataList: [],
      tableColumns: [
        { slotName: "merchantName" },
        { label: "订单号", prop: "orderNo" },
        { slotName: "state" },
        { label: "差异订单号", prop: "argueNo" },
        { slotName: "aprState" },
        { slotName: "moneyWay" },
        { slotName: "isImportUpAccount" },
        { slotName: "optName" },
      ],
      aprStateList: [
        { value: "00", label: "待平台初审" },
        { value: "01", label: "待复核打款" },
        { value: "02", label: "待门店打款" },
        { value: "04", label: "追差完成" },
        { value: "10", label: "追差驳回" },
        { value: "20", label: "取消追差" },
      ],
      statusOptions: [
        { label:'待确认', value: '00'},
        { label:'已确认', value: '01'},
        { label:'待支付', value: '02'},
        { label:'已绑码', value: '03'},
        { label:'已收货', value: '04'},
        { label:'降价收货', value: '05'},
        { label:'已取消', value: '10'},
        { label:'已作废', value: '20'},
        { label:'已退回', value: '30'},
      ]
    };
  },

  created() {
    this.getOrderList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getOrderList() {
      let params = {
        batchNo: this.batchNo,
        merchantId: this.merchantId,
      };
      this.loading = true;
      _api
        .getImportLogUpOrderList(params)
        .then((res) => {
          if (res.code === 1) {
            this.dataList = res.data.list;
            this.createTime = res.data.createTime;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 过滤状态
    filteredAprState(state) {
      let label = "";
      this.aprStateList.forEach((item) => {
        if (item.value === state) {
          label = item.label;
        }
      });
      return label;
    },
    // 过滤状态
    filteredOrderState(state) {
      let label = "";
      this.statusOptions.forEach((item) => {
        if (item.value === state) {
          label = item.label;
        }
      });
      return label;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.flex {
  display: flex;
  align-items: center;
}
.nowrap-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.btn {
  color: #0981ff;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
</style>
